<template>
  <el-container class="project-group-main public-page-style" style="padding: 10px 25px">
    <el-container class="project-group-main" style="
        height: calc(100% - 60px) !important;
        padding: 0;
        border: 1px solid #dadada;
        border-top: 1px solid #dadada;
      ">
      <el-container>
        <el-main class="project-body">
          <el-header>
            <el-form :inline="true">
              <el-form-item label="项目名称：">
                <el-input v-model="pjData.projectName" clearable size="mini" placeholder="请输入项目名称"
                  @keydown.native.enter="getProjectTempState">
                </el-input>
              </el-form-item>
              <el-form-item label="SN搜索：">
                <el-input clearable v-model="pjData.dtuSn" size="mini" placeholder="输入SN搜索项目"
                  @keydown.native.enter="getProjectTempState"></el-input>
              </el-form-item>
              <!-- <el-form-item label="用户搜索：">
          <el-input
          clearable
            v-model="pjData.createId"
            size="mini"
            placeholder="输入用户"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目组搜索：">
          <el-input
          clearable
            v-model="pjData"
            size="mini"
            placeholder="输入项目组"
          ></el-input>
        </el-form-item> -->
              <el-button class="project-group-select-btn" type="primary" plain size="mini"
                @click.stop="getProjectTempState">查 询</el-button>
              <el-button class="project-group-binding-btn" type="primary" plain size="mini"
                @click="distributeProject()">关联项目</el-button>
            </el-form>
          </el-header>
          <!--项目状态表格 -->
          <div class="project-body-item">
            <el-table @selection-change="handleSelectionChange" ref="multipleTable" stripe
              :header-cell-style="{background:'#eef1f6',color:'#606266'}" highlight-current-row :data="projectPage"
              style="width: 100%">
              <el-table-column type="selection" width="55">
              </el-table-column>
              <el-table-column align="center" prop="projectName" label="设备名称">
              </el-table-column>
              <el-table-column label="所属用户" prop="username" align="center"></el-table-column>
              <el-table-column align="center" prop="trueState" label="真实状态">
                <template slot-scope="scope">
                  <el-tag size="small" v-if="scope.row.trueState == 1" type="success">正常</el-tag>
                  <el-tag size="small" v-if="scope.row.trueState == 2" type="info">离线</el-tag>
                  <el-tag type="danger" size="small" v-if="scope.row.trueState == 3">报警</el-tag>
                  <el-tag type="success" size="small" v-if="scope.row.serverType == 1">三方</el-tag>
                  <el-tag size="small" v-if="scope.row.trueState == 4" type="warning">故障</el-tag>
                </template>
              </el-table-column>

              <el-table-column align="center" prop="address" label="修改状态">
                <template slot-scope="scope">
                  <el-col>
                    <span type="text" :class="{stateColor:scope.row.projectState==1}">在线</span>
                    <span>/</span>
                    <span type="text" :class="{stateColor:scope.row.projectState==2}">离线</span>
                  </el-col>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="modifyTime" label="更新日期">
              </el-table-column>
            </el-table>
          </div>
          <el-row type="flex" justify="end" style="margin: 0 50px 20px 0;">
            <el-button style="background-color: #ecf5ff;color:#409eff" type="primary" plain size="mini"
              @click="updateState" class="stateButton">修改状态</el-button>
            <el-button type="danger" plain size="mini" @click="deleteState">删除</el-button>
          </el-row>
          <el-pagination @size-change="projectSizeChange" @current-change="projectCurrentChange"
            :current-page="pjData.current" :page-size="pjData.size" layout="total, prev, pager, next, jumper"
            :total="pjData.total" class="pagination"></el-pagination>
        </el-main>
      </el-container>
      <!-- 关联项目表单 -->
      <el-dialog top="3%" :title="formTitle" :show-close="false" :visible.sync="projectDialog" :destroy-on-close="false"
        :close-on-click-modal="false" @close="close" class="iot-dialog" width="50%">
        <el-form ref="bindRef" label-width="120px">
          <el-form-item label="账号列表：">
            <!-- <el-select
            v-model="createId"
            filterable
            placeholder="请选择"
            @change="userChange"
          >
            <el-option
              v-for="item in userArr"
              :key="item.userId"
              :label="item.fullName"
              :value="item.userId"
            >
            </el-option>
          </el-select> -->
            <el-cascader  :options="options" :props="props" v-model="createId" filterable clearable
              @change="userChange">
            </el-cascader>
          </el-form-item>
          <el-form-item label="项目组：">
            <el-select v-model="projectGroupId" placeholder="请选择" @change="projectGroupChange" filterable>
              <el-option v-for="item in projectGroupChangeArr" :key="item.projectGroupId" :label="item.projectGroupName"
                :value="item.projectGroupId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所选项目">
            <el-checkbox-group v-model="projectIds">
              <el-checkbox :disabled="item.isShow" v-for="item in projectArr" :label="item" :key="item.projectId">{{
                item.projectName }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="projectPages.current"
          :page-size="projectPages.size" layout="total, prev, pager, next, jumper" :total="projectPages.total"
          class="pagination"></el-pagination>
        <div slot="footer">
          <el-button type="primary" plain size="mini" @click="submit()">确 定</el-button>
          <el-button type="info" plain size="mini" @click="close">取 消</el-button>
        </div>
      </el-dialog>
    </el-container>
  </el-container>
</template>

<script>
  let that;
  export default {
    name: "ProjectGroup",
    data() {
      return {
        props: {
          lazy: true,
          checkStrictly: true,
          emitPath: false,
          lazyLoad(node, resolve) {
            if(node.value){  that.userChange(node.value)}else{that.userChange(that.$store.state.userId)}
            return that.getlazyLoad(node, resolve)
          }
        },
        options: [],
        newProjectIds: [],
        projectIds: [],
        projectGroupId: '',
        projectPages: {
          size: 20,
          current: 1,
          projectGroupId: '',
          projectName: '',
        },
        projectPage: [],
        dtuSn: '',
        projectArr: [],
        projectGroupChangeArr: [],
        createId: '',
        pjData: {
          projectName: "",
          dtuSn: '',
          size: 10,
          current: 1,
          total: 0,
          createId: this.$store.state.userId
        },
        projectDialog: false,
        formTitle: "关联项目",
        userArr: [],
        multipleSelection: []
      };
    },
    created() {
      that = this
    },
    methods: {
      // 级联查找
      getlazyLoad(node, resolve) {
        const { level } = node;
        let type = level == 0 ? that.$store.state.userId : node.value
        const nodes = []
        // if (level == 1 && type == that.$store.state.userId) {
        //   node.loading = false
        //   node.loaded=true
        //   return
        // }
        that.$api.projectGroup.searchUserChild(type).then((res) => {
          if (res.code == 200) {
            node.loading = false
            node.loaded = true
            if (res.data.length > 0) {
              res.data.map((item) => {
                let obj = {
                  value: item.userId,
                  label: item.fullName,
                  leaf: !item.children
                }
                nodes.push(obj)
              })
              if (level == 0) {
                nodes.unshift({ value: that.$store.state.userId, label: that.$store.state.name, leaf: true })
                that.options = nodes
              }
            }
            resolve(nodes);
          }
        })
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //更新状态
      updateState() {
        if (that.multipleSelection.length < 1) {
          this.$message.warning('请先选择设备，再修改状态')
          return
        }
        let data = []
        that.multipleSelection.forEach((item) => {
          let tempState = item.projectState == 1 ? 2 : 1
          data.push({
            tempState,
            projectId: item.projectId,
          })
        })
        that.$api.projectTempState.updateState(data).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.getProjectTempState()
          }
        })
      },
      //删除状态
      async deleteState() {
        try {
          await this.$confirm('此操作将删除设备,可重新添加,是否继续？', '删除操作', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          let data = []
          that.multipleSelection.forEach(item => {
            data.push(item.projectId)
          })
          await that.$api.projectTempState.deleteState(data).then((res) => {
            if (res.code == 200) {
              that.$message.success(res.msg)
              that.getProjectTempState()
            }
          })
        } catch (e) {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }

      },
      // 分页数据
      getProjectTempState() {
        this.$api.projectTempState.getProjectTempState(that.pjData).then((res) => {
          if (res.code == 200) {
            that.projectPage = res.data.records
            that.pjData.total = res.data.total
          }
        })
      },

      //添加状态
      submit() {
        //获取已添加数据
        let obj = {};
        that.newProjectIds = that.newProjectIds.reduce(function (item, next) {
          obj[next.projectId] ? '' : obj[next.projectId] = true && item.push(next);
          return item;
        }, []);
        //筛选未添加的数据
        that.newProjectIds.forEach((item) => {
          that.projectIds = that.projectIds.filter(it => item.projectId != it.projectId)
        })
        let data = []
        if (that.projectIds.length < 1) {
          this.$message.warning('请先选择设备,再进行添加')
          return
        }
        that.projectIds.forEach((item) => {
          data.push({
            trueState: item.projectState,
            createId: this.createId,
            projectGroupId: this.projectGroupId,
            projectId: item.projectId,
          })
        })
        that.$api.projectTempState.addProjectTempState(data).then((res) => {
          if (res.code == 200) {
            that.$message.success(res.msg)
            that.close()
            that.getProjectTempState()
          }
        })
      },
      close() {
        that.projectDialog = false
        that.projectIds = []
      },
      SizeChange(val) {
        that.projectPages.size = val
        that.projectGroupChange()
      },
      CurrentChange(val) {
        that.projectPages.current = val
        that.projectGroupChange("current")
      },
      projectSizeChange(val) {
        that.pjData.size = val
        that.getProjectTempState()
      },
      projectCurrentChange(val) {
        that.pjData.current = val
        that.getProjectTempState()
      },
      // 点击打开弹层
      distributeProject() {
        that.projectDialog = true;

      },
      //用户
      // async getUserNoPage() {
      //   await that.$api.projectGroup.userNoPage().then((response) => {
      //     if (200 == response.code) {
      //       that.userArr = response.data;
      //     }
      //   });
      //   that.createId = that.userArr[0].userId
      //   await that.userChange()
      //   that.projectGroupId = that.projectGroupChangeArr[0].projectGroupId
      //   // await  that.projectGroupChange()
      // },
      //用户改变得到项目组
      async userChange(val) {
        document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function () {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
        });
         if(!that.createId) return
        await that.$api.projectGroup.getProjectGroupNoPage(that.createId).then((res) => {
          that.newProjectIds = []
          that.projectIds = []
          that.projectGroupChangeArr = []
          that.projectArr = []
          that.projectGroupId = ''
          if (200 == res.code) {
            that.projectGroupChangeArr = res.data
            that.projectGroupId = res.data[0].projectGroupId
            that.projectGroupChange()
          }
          return
        })
      },
      //根据项目组查询设备
      async projectGroupChange(a) {
        if (a != 'current') {
          that.projectPages.current = 1
        }
        //分页获取项目列表
        that.projectPages.projectGroupId = that.projectGroupId
        await that.$api.project.getProjectPage(that.projectPages).then((res) => {
          if (200 == res.code) {
            that.projectArr = res.data.records
            that.projectPages.total = res.data.total
          }
          return
        })
        let data = {
          createId: this.createId,
          projectGroupId: this.projectGroupId
        }
        // 获取已选中的设备
        await that.$api.projectTempState.getStateList(data).then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              that.projectArr.find((it) => {
                if (item.projectId == it.projectId) {
                  this.newProjectIds.push(it)
                  this.projectIds.push(it)
                  it.isShow = true
                }
              })
            })
          }
          return
        })
      },
    },
    beforeMount() {
      that = this;
    },
    mounted() {
      that.getProjectTempState()
      that.createId=that.$store.state.userId
    },
  };
</script>

<style scoped>
  .stateButton:hover {
    background-color: #409eff !important;
    color: #f3f9ff !important
  }

  .project-group-select-btn {
    margin-top: 7px;
  }

  .project-group-add-btn {
    float: right;
    margin-right: -18px;
    /* margin: 7px 30px; */
  }

  .project-group-main {
    position: relative;
    height: calc(100% - 40px);
    margin-top: 20px;
    padding: 5px;
  }

  .project-group-main>>>.el-card__header {
    padding: 0 18px;
  }

  .project-group-body {
    /* border-radius: 5px; */
    line-height: 30px;
    padding: 10px;
    /* box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%); */
    /* border: 5px solid #d9dde488; */
  }

  .project-body {
    width: 80%;
    float: right;
    max-height: 900px;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f7fa;
  }

  .project-group-binding-btn {
    float: right;
    margin-right: -15px;
  }

  .pagination {
    width: 97%;
    text-align: right;
  }

  .pagination>>>.btn-next,
  .pagination>>>.btn-prev,
  .pagination>>>.el-pager li,
  .pagination>>>.el-input__inner {
    background-color: rgba(0, 0, 0, 0);
  }

  .project-body-item {
    /* display: flex;
  flex-wrap: wrap;
  align-content: space-around; */
    height: 560px;
    padding: 10px;
    overflow-y: auto;
  }

  .project-body-item .el-button {
    color: #aaa
  }

  .stateColor {
    color: #889eff !important;
    font-weight: 700;
  }

  .project-group-main>>>.search-btn {
    display: none !important;
  }

  .project-group-main>>>.el-vue-search-box-container {
    width: 250px;
  }

  .project-group-main>>>.el-vue-search-box-container .search-box-wrapper {
    -webkit-appearance: none;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 5px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .detailDialog>>>.el-dialog {
    border-radius: 5px;
  }

  .detailDialog>>>.el-dialog__body {
    padding: 0;
  }

  .detailDialogs>>>.el-dialog__body {
    padding: 0 0 60px 0;
  }

  .iot-project-status {
    float: right;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    margin-top: -10px;
    margin-right: 10px;
    cursor: pointer;
    transform: scale(0.75, 0.75);
  }

  .iot-search-item>>>.el-form-item {
    margin-bottom: 15px;
    width: inherit;
  }

  .iot-search-input>>>.el-input__inner {
    height: 30px;
    font-size: 10px;
  }

  .project-group-main>>>.el-button--danger.is-plain,
  .project-group-main>>>.el-tag--danger {
    color: #e6212e;
  }

  .iot-chart-div {
    position: relative;
    width: 100%;
    height: 250px;
    /* display: flex;
  justify-content: space-around; */
  }

  .select {
    font-size: 16px;
    font-weight: bold;
    color: rgb(30, 170, 57);
  }
</style>